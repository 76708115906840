<template>
  <div>
    <SidebarComponent :username="appStore.getEmail" :value="value" />
    <div class="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
      <HeaderComponent @toggle="toggle()" />
      <div class="px-6 pt-6 2xl:container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToggle } from '@vueuse/core'
import { useAppStore } from '~/stores/app'
import { useAuthV2 } from '~/composable/useAuthV2'

const appStore = useAppStore()
const [value, toggle] = useToggle()
const { loadUserInfo } = useAuthV2()
const router = useRouter()
const { error } = await loadUserInfo()

if (error.value && error.value?.data && (error.value?.data.code === 401 || error.value?.data.code === 403)) {
  router.push({ path: '/login' })
}

// TODO Do it in an interval on every page.
// Redirect to company creation if the user is a company without company info
if (appStore.isGrantedCompany && appStore.getUser.companies && appStore.getUser.companies.length === 0) {
  router.push({ path: '/company/create' })
}

if (appStore.isGrantedCandidate && !appStore.getUser.candidateProfile) {
  router.push({ path: '/candidate/create' })
}
</script>

<style scoped>

</style>
